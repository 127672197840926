<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Admin Detail</h4>

      <div class="w-full flex lg:justify-end" align="right">
        <div>
          <vs-button size="normal" @click="saveData" class="m-2"
            >Save</vs-button
          >
          <!-- <vs-button size="normal" @click="popUpDelete = true" class="m-2" color="danger" >Delete</vs-button> -->
          <vs-button
            @click="resetPassword = true"
            icon-pack="feather"
            class="m-2"
            >Change Password</vs-button
          >
        </div>
        <vs-popup :active.sync="popUpDelete" title="Delete Confirmation">
          <p align="center">
            Are you sure you want to delete this sub franchise?
          </p>
          <br />
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
            vs-mt="6"
          >
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5" align="right">
                <vs-button
                  size="lg"
                  color="primary"
                  @click="deleteSubFranchiseHandler"
                  >Yes, delete</vs-button
                >
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-button size="lg" color="danger" @click="popUpDelete = false"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </vs-popup>
      </div>

      <vs-form autocomplete="off">
        <div class="flex flex-wrap">
          <div class="w-full flex flex-wrap">
            <div class="flex w-full lg:w-1/2">
              <div class="w-full m-5">
                <label for>Disable User</label>
                <vs-switch
                  color="danger"
                  id="userStatusLoader"
                  @click="changeStatus(userStatus)"
                  class="m-2"
                  v-model="userStatus"
                />
                <div
                  v-if="statusChangedClick"
                  style="width: 20px; height: auto; display: flex"
                >
                  <span style="margin-right: 5px">Processing</span>

                  <img
                    src="../../../../public/loader.gif"
                    style="width: 100%; height: 100%"
                  />
                </div>
              </div>
            </div>
            <div class="flex w-full lg:w-1/2">
              <div class="w-full m-5">
                <vs-input
                  :success="
                    !errors.first('basic.firstName') &&
                    franchiseData.firstName != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="errors.first('basic.firstName') ? true : false"
                  v-validate="'required|max:150'"
                  name="firstName"
                  data-vv-as="first name"
                  label="First Name"
                  class="w-full"
                  data-vv-scope="basic"
                  :danger-text="errors.first('basic.firstName')"
                  v-model="franchiseData.firstName"
                />
              </div>
            </div>
          </div>
          <div class="w-full flex flex-wrap">
            <div class="flex w-full lg:w-1/2">
              <div class="w-full m-5">
                <vs-input
                  :success="
                    !errors.first('basic.lastName') &&
                    franchiseData.lastName != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="errors.first('basic.lastName') ? true : false"
                  v-validate="'required|max:150'"
                  name="lastName"
                  data-vv-as="last name"
                  label="Last Name"
                  class="w-full"
                  data-vv-scope="basic"
                  :danger-text="errors.first('basic.lastName')"
                  v-model="franchiseData.lastName"
                />
              </div>
            </div>
            <div class="flex w-full lg:w-1/2">
              <div class="w-full m-5">
                <vs-input
                  :success="
                    !errors.first('basic.contactNumber') &&
                    franchiseData.contactNumber != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="errors.first('basic.contactNumber') ? true : false"
                  v-validate="'required|max:150'"
                  name="contactNumber"
                  data-vv-as="contact number"
                  label="Contact Number"
                  class="w-full"
                  data-vv-scope="basic"
                  :danger-text="errors.first('basic.contactNumber')"
                  v-model="franchiseData.contactNumber"
                />
              </div>
            </div>
          </div>
          <vs-col
            vs-w="6"
            id="profileImageUpload"
            class="vs-con-loading__container"
          >
            <label class="ml-5">Upload Profile Picture</label>
            <div id class="m-5">
              <div
                class="mb-4"
                v-if="franchiseData.profileImage"
                style="margin: auto; width: 35%"
              >
                <vs-avatar
                  size="130px"
                  align="center"
                  :src="franchiseData.profileImage"
                />
              </div>

              <div style="text-align: center; position: relative">
                <label
                  class="image-upload"
                  style="
                    border: 1px solid #cccccc;
                    display: inline-block;
                    width: 100%;
                    border-radius: 5px;
                    padding: 10px;
                  "
                >
                  Upload a profile photo
                  <input
                    type="file"
                    accept="image/*"
                    @change="uploadImage($event, 'profileImageUpload')"
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
          </vs-col>
        </div>
      </vs-form>
    </vs-card>
    <div id="resetPasswordContainer">
      <vs-card v-if="resetPassword == true">
        <h4 class="mb-2 ml-5 mt-2">Reset Password</h4>
        <form autocomplete="off">
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
          >
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  :success="
                    !errors.first('resetPassword.newPassword') && password != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.newPassword') ? true : false
                  "
                  name="newPassword"
                  :danger-text="errors.first('resetPassword.newPassword')"
                  label="New Password"
                  class="w-full"
                  v-validate="{
                    required: true,
                    min: 6,
                    regex:
                      /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                  }"
                  data-vv-as="new password"
                  data-vv-scope="resetPassword"
                  ref="password"
                  icon-no-border
                  icon-pack="feather"
                  v-model="password"
                  :icon="passwordIcon"
                  :type="passwordType"
                  @click.native="showHidePassword($event)"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  label="Confirm Password"
                  :success="
                    !errors.first('resetPassword.confirmPassword') &&
                    confirmPassword != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.confirmPassword') ? true : false
                  "
                  name="confirmPassword"
                  :danger-text="errors.first('resetPassword.confirmPassword')"
                  v-validate="'required|confirmed:password'"
                  data-vv-as="confirm password"
                  data-vv-scope="resetPassword"
                  class="w-full"
                  icon-no-border
                  icon-pack="feather"
                  v-model="confirmPassword"
                  :icon="confirmPasswordIcon"
                  :type="confirmPasswordType"
                  @click.native="showHideConfirmPassword($event)"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-button
                  size="lg"
                  @click="changePassword"
                  icon-pack="feather"
                  class="mr-2"
                  >Reset Password</vs-button
                >
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5" align="right">
                <vs-button
                  @click="resetPassword = false"
                  color="danger"
                  icon-pack="feather"
                  class="mr-2"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </form>
      </vs-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import axios from "@/axios";

const dict = {
  custom: {
    newPassword: {
      required: "Please enter your password",
      min: "Password must be at least 6 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    confirmPassword: {
      required: "Please enter your confirm password",
      confirmed: "The password confirmation does not match",
    },
  },
};
Validator.localize("en", dict);

export default {
  data: () => ({
    userStatus: false,
    statusChangedClick: false,
    franchiseId: "",
    password: "",
    confirmPassword: "",
    franchiseData: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      profileImage: "",
      status: "",
    },
    tempFranchiseData: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      profileImage: "",
      status: "",
    },
    resetPassword: false,
    popUpDelete: false,
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
    confirmPasswordType: "password",
    confirmPasswordIcon: "icon icon-eye-off",
  }),
  methods: {
    ...mapActions("franchise", [
      "updateFranchiseAdmin",
      "fetchFranchiseAdminDetail",
      "deleteSubFranchise",
    ]),
    ...mapActions("clinic", ["resetClinicPassword"]),
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async changeStatus(status) {
      this.statusChangedClick = true;
      let us = "Active";
      if (!status) us = "Disabled";

      this.tempFranchiseData.status = us;
      await this.updateFranchiseAdmin({
        franchiseData: this.tempFranchiseData,
        franchiseId: this.franchiseId,
      })
        .then((res) => {
          // this.doctorData.emailVerifiedAt = res.data.data.emailVerifiedAt;
          this.$vs.notify({
            title: "Success",
            text: "Status updated successfully.",
            color: "success",
          });
          this.statusChangedClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update status. Try again!",
            color: "danger",
          });
          this.statusChangedClick = false;
        });
    },
    async saveData() {
      let self = this;
      let isValid = await this.$validator.validateAll("basic");
      if (isValid) {
        if (this.userStatus == true) this.franchiseData.status = "Disabled";
        else this.franchiseData.status = "Active";

        await this.updateFranchiseAdmin({
          franchiseData: this.franchiseData,
          franchiseId: this.franchiseId,
        })
          .then((res) => {
            this.getFranchiseAdminDetail(this.franchiseId);
            self.$vs.notify({
              title: "Sub Franchise Admin Updated",
              text: "Sub franchise admin updated successfully.",
              color: "success",
            });
          })
          .catch((err) => {
            self.$vs.notify({
              title: "Failed",
              text: "Failed to update franchise admin. Try again!",
              color: "danger",
            });
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err, "basic");
            }
            if (err.status === 417) {
              self.errors.add({
                field: "email",
                msg: err.data.message,
                scope: "basic",
              });
            }
          });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
          scope: form,
        });
      });
    },
    async getFranchiseAdminDetail(id) {
      let self = this;
      await this.fetchFranchiseAdminDetail(id).then((res) => {
        const franchise = res.data.data;
        self.franchiseId = franchise._id;
        self.franchiseData.firstName = franchise.firstName;
        self.franchiseData.lastName = franchise.lastName;
        self.franchiseData.contactNumber = franchise.contactNumber;
        self.franchiseData.profileImage = franchise.profileImage;
        if (franchise.status == "Active") this.userStatus = false;
        else this.userStatus = true;

        self.tempFranchiseData.firstName = franchise.firstName;
        self.tempFranchiseData.lastName = franchise.lastName;
        self.tempFranchiseData.contactNumber = franchise.contactNumber;
        self.tempFranchiseData.profileImage = franchise.profileImage;
      });
    },
    async changePassword() {
      let self = this;
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        await this.resetClinicPassword({
          id: this.franchiseId,
          data: {
            password: this.password,
            confirmPassword: this.confirmPassword,
          },
        }).then((res) => {
          self.password = "";
          self.confirmPassword = "";
          self.$validator.reset();
          self.$vs.notify({
            title: "Sub franchise admin password reset",
            text: "Sub franchise admin's password reset Successfully.",
            color: "success",
          });
        });
      }
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
        scale: 0.6,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          console.log(res.data.Location);
          self.franchiseData.profileImage = res.data.Location;

          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    deleteSubFranchiseHandler() {
      let user = this.$store.state.AppActiveUser.userRole;
      this.popUpDelete = false;
      let id = this.franchiseId;
      this.deleteSubFranchise(id).then((res) => {
        this.$vs.notify({
          subject: "Deleted Sub Franchise",
          text: "Sub Franchise deleted Successfully.",
          color: "success",
        });
        if (user === "superAdmin") {
          this.$router.push("/super-admin/sub-franchises");
        } else if (user === "subFranchise") {
          this.$router.push("/franchise/sub-franchises");
        } else {
          this.$router.push("/" + user + "/sub-franchises");
        }
      });
    },
  },
  async created() {
    this.franchiseId = this.$route.params.franchiseId;
    await this.getFranchiseAdminDetail(this.franchiseId);
  },
};
</script>
